import React, { useContext } from 'react';

import { LoadingContext } from '@src/utility/context/LoadingContext';
import ComponentSpinner from '../spinner/Loading-spinner';

import './styles.scss';

export const LoadingGlobal: React.FC = () => {
  const loaderContext = useContext(LoadingContext);

  const { loading } = loaderContext;

  return (
    <div>
      {loading ? (
        <div id="modal-root">
          <div className="spinner-box">
            <ComponentSpinner />
            <span className="mt-1">Aguarde...</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};
